$red: #920203;
$primary: $red;
$primary-complement: #029291;
$primary-triadic-1: #039202;
$primary-triadic-2: #020392;
$primary-analogous-1: #924902;
$primary-analogous-2: #92024b;
$primary-split-1: #029249;
$primary-split-2: #024b92;
$primary-square-1: #4b9202;
$primary-square-2: #490292;
$primary-mono-1: #750202;
$primary-mono-2: #af0204;

$blue: $primary-split-2;
$green: $primary-triadic-1;
$purple: $primary-square-2;
$pink: $primary-analogous-2;
$orange: $primary-analogous-1;
$teal: $primary-complement;

$page-header-height: 75px;
$page-footer-height: 50px;
$page-header-spacing: 24px;

@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
