@use '../../../theme/variables';

.card {
  :global(.card-img-overlay) {
    background-color: rgba(variables.$gray-100, 0.6);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  :global(.card-title) {
    text-align: center;
    &,
    & > a {
      color: variables.$red;
    }
  }
  :global(.card-text) {
    color: variables.$primary-text-emphasis;
  }

  .cardCountBadge {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    margin-top: variables.$grid-gutter-width / 2;
    margin-right: variables.$grid-gutter-width / 2;
  }
}
