.searchSelect {
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);

  :global(.react-select__option:active) {
    background-color: var(--bs-dropdown-link-hover-bg);
    color: var(--bs-dropdown-link-hover-color);
  }
  :global(.react-select__option--is-focused) {
    background-color: var(--bs-dropdown-link-hover-bg);
    color: var(--bs-dropdown-link-hover-color);
  }
}

.searchSelectLabel {
  display: flex;
  align-items: center;
  div {
    flex-grow: 1;
    white-space: nowrap;
  }
  img {
    max-width: 30px;
    aspect-ratio: 1;
    margin-right: 5px;
  }
  small {
    display: block;
    font-size: 0.8rem;
  }
}
